import { Footer } from "../../Components/Footer/Footer"
import { Homepage as HomepageComponent} from "../../Components/Homepage/Homepage"

export const Homepage = () => {
    return (
        <div className="Homepage-Pages">
            <HomepageComponent />
            <Footer/>
        </div>
    )
}