import "./Timer.css"
import CountUp from 'react-countup';
import { useState } from "react";

type Props = {
    days:number
    header?:string
}

export const Timer: React.FC<Props> = (props) => {

    const [countDaysAnimation, setCountDaysAnimation] = useState(10);

    return (
        <div className="Timer">
            <CountUp
                className="countdown"
                start={365}
                end={props.days + 1}
                duration={countDaysAnimation}
                separator=" "
            />
            <p onClick={() => {setCountDaysAnimation(0)}}>Stop Animation</p>
            <p>{props.header}</p>
        </div>
    )   
}