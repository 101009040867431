import "./Homepage.css"

import { useState } from "react"
import Select from 'react-dropdown-select'

import { Modal } from "../Modal/Modal"
import { all_countries } from "../../Constants/index"

export const Homepage = () => {

    const [data, setData] = useState<{value:string, label:string}[]>([])

    //Do we have an API for states of respective country
    const handleChange = (obj: {value:string, label:string}) => {
        let temp:{value:string, label:string}[] = []
        temp.push(obj)
        setData(temp)
    }

    return (
        <div className="Homepage" id="top">
            <div className="Homepage__selection">
                <p>WHERE YOU AT</p>
                <Select
                    placeholder="Select Country"
                    options={all_countries}
                    values={[]}
                    onChange={(values) => values[0] && handleChange(values[0])}
                    className="Select"                    
                />
                {/* {showState &&
                    <Select
                        placeholder="Select State"
                        options={all_states_usa}
                        values={[]}
                        onChange={(values) => values[0] && handleChange_States(values[0])}
                        className="Select"
                    />
                } */}
            </div>
            {<Modal data={data}/>}
        </div>   
    )
}