/* eslint-disable react/jsx-no-target-blank */

import { useState } from 'react'
import Rodal from 'rodal'
import "./Footer.css"

export const Footer = () => {

    const [more, setMore] = useState(false);

    return (
        <div className="Footer" id="Footer__top">
            {/* eslint-disable-next-line  */}
            <p className="bold"><a href="https://www.pphilip.com" target="_blank">Website By Paul</a></p>
            <p onClick={() => setMore(true)}>
                {/* eslint-disable-next-line */}
                <a>View More</a>
            </p>

            <Rodal 
                visible={more} 
                onClose={() => setMore(false)}
                enterAnimation="slideLeft"
                leaveAnimation="slideLeft"
                duration={825}
                closeOnEsc
                className="opaque"
                showMask={false}
            >
                <div className="Footer__Rodal">
                    <p>Framework: React.ts</p>
                    <p>NPM -v 6.14.12</p>
                    <p><a href="https://calendarific.com/api-documentation" target="_blank">API by Calendarific</a></p>
                    <p><a href="https://www.pphilip.com/contact" target="_blank">Contact Me</a></p>
                </div>
            </Rodal>
        </div>
    )
}