import './App.css'
import 'rodal/lib/rodal.css'
import { Homepage } from './Pages/Homepage/Homepage'
import ReactGA from 'react-ga'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

const TRACKING_ID = "UA-193668943-2";
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/" exact component={Homepage}/>
        </Switch>    
      </div>
    </Router>
  );
}

export default App;
