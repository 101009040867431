import "./Maps.css"
import { useEffect } from "react"
import { WorldMap } from "react-svg-worldmap"
import { ExpandMoreOutlined } from "@material-ui/icons"
import USAMap from "react-usa-map"

type Props = {
    data:{value:string, label:string}[]
    error:boolean
}

export const Maps:React.FC<Props> = (props) => {

    useEffect(() => {
        autoScrollToResults(5500)
    }, [])

    const autoScrollToResults = (delay:number) => {
        const element = document.getElementById('Scroll_button');
        setTimeout(() => {
            element?.click();
        }, delay)
    }

    const statesCustomConfig = (state:any) => {
        return {
            [state]: {
                fill: "rgb(255, 121, 121)",
            }
        };
    };

    return (
        <div className="Maps" id="top">
            <p className="Maps__title">{props.data[0].label}</p>
            <p>{props.error && "Unfortunately we do not have an API for this country yet."}</p>
            <WorldMap 
                color="rgb(255, 121, 121)" 
                size="responsive" 
                data={[{country: props.data[0].value, value: 1}]}
                key={props.data[0].value}
                backgroundColor="transparent"
                frameColor="white"
                borderColor="white"
                strokeOpacity={0.6}
            />
            {props.data[1] &&
                <>
                    <p className="Maps__title">{props.data[1].label}</p>
                    <USAMap 
                        customize={statesCustomConfig(props.data[1].value)} 
                        defaultFill="#cccccc" 
                    />
                </>
            }
            {!props.data[1] && !props.error && 
                <a href="#Modal__results" className="Maps__scroll" id="Scroll_button"><ExpandMoreOutlined fontSize="large" style={{color: "white"}}/></a>
            }
        </div> 
    )
}