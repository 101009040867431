import "./Modal.css"
import { useEffect, useState } from "react"
import { Maps } from "../Maps/Maps"
import { Timer } from "../Timer/Timer"
import Rodal from 'rodal'
import Chip from '@material-ui/core/Chip'
import Button from '@material-ui/core/Button'
import Loader from "react-loader-spinner"
import Backdrop from '@material-ui/core/Backdrop'
import PublicOutlinedIcon from '@material-ui/icons/PublicOutlined'
import moment from "moment"

type Props = {
    data:{value:string, label:string}[]
}

export const Modal: React.FC<Props> = (props) => {

    const [error, setError] = useState<boolean>(false)
    const [isAPILoaded, setIsAPILoaded] = useState<boolean>(true)
    const [data, setData] = useState<{value:string, label:string}[]>([])
    const [holidays, setHolidays] = useState<any>([])

    useEffect(() => {
        setData(props.data)
        getHolidays(props.data)
        //eslint-disable-next-line
    }, [props.data])

    //Styles
    const styles_chip_local = {
        borderColor: "rgb(69, 126, 81)",
        color: "rgb(69, 126, 81)",
        maxWidth: "20rem",
    }
    const styles_chip_national = {
        borderColor: "rgb(255, 66, 66)",
        color: "rgb(255, 66, 66)",
        maxWidth: "20rem",
    }

    //API
    async function getHolidays(data:{value:string}[]) {
        try {
            setIsAPILoaded(false)
            setError(false)
            let response_countries_uf = await fetch(`https://whenismynextholidaysbackend.com/holidays/${data[0].value}/`)
            let response_countries = await response_countries_uf.json()

            //If no API
            if (response_countries[0] === "None") {
                response_countries = []
                setError(true)
            } 
            else {
                response_countries.forEach((item:any) => item.date = item.date.iso)
                setHolidays(response_countries)
            }
            setIsAPILoaded(true)
        }
        catch {
            setIsAPILoaded(true)
            setError(true)
            return []
        }  
    }

    const daysBetween = (date:string) => (moment().diff(date, 'days') * -1)

    return (
        <> 
            {/* Div will hide when API is complete */}
            <Backdrop className="backdrop" open={!isAPILoaded} transitionDuration={1000}>
                <div className="loader">
                    <Loader
                        type="Puff"
                        color="#508FCD"
                        height={100}
                        width={100}
                    />
                    <p>Loading</p>
                </div>
            </Backdrop>

            {data[0] &&
                <Rodal 
                    visible={data[0] && isAPILoaded} 
                    onClose={() => setData([])}
                    enterAnimation="slideRight"
                    leaveAnimation="slideRight"
                    duration={825}
                    closeOnEsc
                    className="rodal"
                    showMask={false}
                >
                    <div className="Modal">

                        {/* Maps if exist */}
                        <Maps data={data} error={error}/>

                        {holidays[0] && 
                            <div className="Modal__results">

                                {/* Latest holidays */}
                                <div className="Modal__results__next" id="Modal__results">
                                    <Timer days={daysBetween(holidays[0].date)} header={"Days Left To:"}/>
                                    <p>{moment(holidays[0].date).format("Do MMMM YYYY")}</p>
                                    <p>{holidays[0].name}</p>
                                    <p>{holidays[0].description}</p>
                                    <Chip
                                        className="chip"
                                        style={holidays[0].type.toString().toUpperCase() === "LOCAL HOLIDAY" ? styles_chip_local : styles_chip_national}
                                        icon={<PublicOutlinedIcon />}
                                        label= {<p style={{whiteSpace: "normal"}}><span>{holidays[0].type} -</span> {!holidays[0].locations ? holidays[0].states : holidays[0].locations}</p>}
                                        variant="outlined"
                                    />
                                </div>
                                <hr/>

                                {/* Upcoming holidays */}
                                <Timer days={holidays.length - 2} header={"Upcoming Holidays This Year"}/>
                                <div className="Modal__results__upcoming">
                                    {holidays.slice(1).map((item:any) => (
                                        <div className="Modal__results__upcoming__container">
                                            <p>{item.name}</p>
                                            <p>{item.date}</p>
                                            <Chip
                                                style={item.type.toString().toUpperCase() === "LOCAL HOLIDAY" ? styles_chip_local : styles_chip_national}
                                                icon={<PublicOutlinedIcon />}
                                                label= {<p style={{whiteSpace: "normal"}}><span>{item.type} -</span> {!item.locations ? item.states : item.locations}</p>}
                                                variant="outlined"
                                            />
                                        </div>
                                    ))}
                                </div>

                                {/* Close & intro */}
                                <Button 
                                    className="button" 
                                    variant="outlined" 
                                    color="primary"
                                    onClick={() => {
                                        setData([])
                                        setHolidays([])
                                    }}
                                >
                                    SELECT COUNTRY
                                </Button>
                                <p className="Modal__results__contact">-</p>
                            </div>
                        }
                    </div> 
                </Rodal>
            }
        </>
    )   
}
